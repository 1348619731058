<template>
  <div>
    <cp-head-table
      title="System FAQ Questions"
      title-icon="ios-build"
      button-title="Add Question"
      button-icon="ios-add-circle-outline"
      @onCreateElem="toCreateModal"
    />

    <cp-table
      ref="cpTable"
      get-data-action="systemQuestions/getSystemQuestionsList"
      :fields="fields"
    >
      <template
        slot="createdAt"
        slot-scope="{ rowData }"
      >
        <span>{{ rowData.item.createdAt | dateFilter }}</span>
      </template>

      <template
        slot="position"
        slot-scope="{ rowData }"
      >
        <div class="d-flex">
          <b-button
            variant="light"
            @click="directItem(rowData.item, true)"
          >
            <i class="ion ion-md-arrow-up" />
          </b-button>
          <b-button
            variant="light"
            class="ml-1"
            @click="directItem(rowData.item)"
          >
            <i class="ion ion-md-arrow-down" />
          </b-button>
        </div>
      </template>

      <template
        slot="actions"
        slot-scope="{ rowData }"
      >
        <b-btn
          v-b-tooltip.hover="true"
          class="mr-3"
          variant="default edit-operator-btn btn-xs md-btn-flat"
          title="Edit"
          @click="toEditModal(rowData.item)"
        >
          <i
            class="ion ion-md-create"
            :style="{ color: '#04bec4' }"
          />
          Edit
        </b-btn>
        <b-btn
          v-b-tooltip.hover="true"
          variant="default edit-operator-btn btn-xs md-btn-flat"
          title="Delete"
          @click="openDeleteModal(rowData.item)"
        >
          <i
            class="ion ion-ios-trash"
            :style="{ color: '#04bec4' }"
          />
          Delete
        </b-btn>
      </template>
    </cp-table>

    <cp-delete-modal
      ref="cpDeleteAlert"
      :item-info="deleteItem"
      @delete-item="removeItem"
    />

    <cp-system-questions-modal
      ref="CpSystemQuestions"
      :title-data="titleData"
      :question-data="questionData"
      @onSaveNewQuestion="saveNewQuestion"
      @onSaveChanging="saveChanging"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import CpHeadTable from '~/components/common/head.vue';
import CpTable from '~/components/shared/cp-table';
import CpSystemQuestionsModal from './system-question-modal.vue';
import CpDeleteModal from '~/components/common/table/deleteModal';

import fields from './fields';

export default {
  name: 'SystemQuestions',

  metaInfo: {
    title: 'System FAQ Questions',
  },

  components: {
    CpTable,
    CpHeadTable,
    CpSystemQuestionsModal,
    CpDeleteModal,
  },

  data() {
    return {
      questionData: {},
      titleData: {
        type: '',
        icon: '',
      },
      fields,
      deleteItem: null,
    };
  },

  methods: {
    ...mapActions('systemQuestions',
      [
        'createSystemQuestion',
        'updateSystemQuestionById',
        'deleteSystemQuestionById',
        'getSystemQuestionById',
      ]),

    showModal() {
      this.$refs.CpSystemQuestions.$refs.systemQuestions.show();
    },

    hideModal() {
      this.$refs.CpSystemQuestions.$refs.systemQuestions.hide();
    },

    openDeleteModal(item) {
      this.deleteItem = item;
      this.$refs.cpDeleteAlert.$refs.deleteAlert.open();
    },

    directItem(item, top) {
      const { position, id } = item;
      let updatePosition = position + 1;
      if (top) updatePosition = !position ? position : position - 1;

      this.updateSystemQuestionById({
        id,
        data: {
          ...item,
          position: updatePosition,
        },
      }).then(() => {
        this.$refs.cpTable.updateTableData();
      });
    },

    saveNewQuestion(params) {
      this.createSystemQuestion(params).then(() => {
        this.$refs.cpTable.updateTableData();
        this.hideModal();
      });
    },

    saveChanging(body) {
      const { id, ...data } = body;
      const params = {
        id,
        data,
      };

      this.updateSystemQuestionById(params).then(() => {
        this.$refs.cpTable.updateTableData();
      }).finally(() => {
        this.hideModal();
      });
    },

    removeItem() {
      const { id } = this.deleteItem;
      this.deleteSystemQuestionById(id).then(() => {
        this.$refs.cpTable.updateTableData();
        this.$refs.cpDeleteAlert.$refs.deleteAlert.close();
        setTimeout(() => { this.deleteItem = null; }, 1000);
      });
    },

    toCreateModal() {
      this.titleData = {
        type: 'Add',
        icon: 'ios-add-circle-outline',
      };

      this.questionData = { position: 0 };
      this.showModal();
    },

    toEditModal({ id }) {
      this.titleData = {
        type: 'Edit',
        icon: 'md-create',
      };

      this.getSystemQuestionById(id).then(({ data }) => {
        this.questionData = data;
        this.showModal();
      });
    },
  },
};
</script>
