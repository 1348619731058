<template>
  <cp-general-modal
    ref="systemQuestions"
    :ok-title="getTitleName"
    size="lg"
    :title="getTitleName"
    :title-icon="`ion ion-${titleData.icon}`"
    :default-value="questionData"
    @onOk="handleSubmit"
  >
    <cp-input
      name="question"
      label="Question"
      validate="required"
    />

    <cp-input
      name="position"
      label="Position"
      input-type="number"
    />

    <cp-select
      name="section"
      label="Section"
      validate="required"
      :options="sections"
    />

    <cp-text-area
      name="answer"
      label="Answer"
      validate="required"
      :rows="10"
    />
  </cp-general-modal>
</template>

<script>

import CpGeneralModal from '~/components/common/modals-components/general-modal';
import { CpInput, CpSelect, CpTextArea } from '~/components/common/standalone-components/inputs';

export default {
  name: 'SystemQuestionsModal',
  components: {
    CpGeneralModal,
    CpInput,
    CpSelect,
    CpTextArea,
  },
  props: {
    titleData: {
      type: Object,
      default: () => {},
    },
    questionData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      model: {},
      sections: [
        'GENERAL QUESTIONS',
        'INVESTMENT REQUIREMENTS',
        'INVESTMENT PROCESS',
        'OFFERING DETAILS',
      ],
    };
  },
  computed: {
    getTitleName() {
      return `${this.titleData.type} Question`;
    },
  },
  watch: {
    questionData(val) {
      this.model = Object.assign({}, val);
    },
  },
  methods: {
    handleSubmit(data) {
      const action = data.id ? 'onSaveChanging' : 'onSaveNewQuestion';
      this.$emit(action, data);
    },
  },
};
</script>
