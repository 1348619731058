export default [
  { key: 'question', sortable: true, tdClass: 'align-middle' },
  { key: 'answer', sortable: true, tdClass: 'align-middle' },
  { key: 'section', sortable: true, tdClass: 'align-middle' },
  { key: 'position', tdClass: 'align-middle' },
  {
    key: 'createdAt', label: 'Created', sortable: true, tdClass: 'align-middle',
  },
  { key: 'actions', label: ' ', tdClass: 'text-nowrap align-middle text-center' },
];
